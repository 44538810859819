'use client';
import { Link } from '@/navigation';
import React, { useEffect } from 'react';
import { logToPayload } from './_utilities/log-to-payload';
import { usePathname } from 'next/navigation';

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}) {
	const pathname = usePathname();

	useEffect(() => {
		logToPayload(error.message, { pathname, ...error });
	}, []);

	return (
		<html lang="en">
			<head>
				{
					<link
						href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap"
						rel="stylesheet"
					/>
				}
			</head>
			<body
				style={{
					fontFamily: "'Mulish', sans-serif",
				}}
			>
				<div
					style={{
						display: 'flex',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							maxWidth: '800px',
							gap: '2.5rem',
						}}
					>
						<h2
							style={{
								margin: 0,
								textAlign: 'center',
								fontSize: '2.25rem',
								fontWeight: 'bold',
								color: '#293455',
							}}
						>
							Midagi läks valesti
						</h2>

						<p
							style={{
								textAlign: 'center',
								lineHeight: '2rem',
								color: '#293455',
								maxHeight: '6rem',
								overflow: 'hidden',
								textOverflow: 'clip',
							}}
						>
							{`Tekkis probleem: ${error?.message}`}
						</p>

						<div
							style={{
								display: 'flex',

								justifyContent: 'center',
							}}
						>
							<button
								onClick={reset}
								style={{
									borderRadius: '0.75rem',
									backgroundColor: '#d1ad73',
									padding: '1rem 1.5rem',
									fontSize: '0.875rem',
									fontWeight: '600',
									color: '#ffffff',
									cursor: 'pointer',
									border: 'none',
									width: 'fit-content',
									display: 'inline-block',
									marginRight: '8px',
								}}
							>
								Proovi uuesti
							</button>
							<Link
								href="/"
								style={{
									display: 'inline-block',
									borderRadius: '0.75rem',
									border: `1px solid #293455`,
									color: '#293455',
									padding: '1rem 1.5rem',
									fontSize: '0.875rem',
									textDecoration: 'none',
									cursor: 'pointer',
									textAlign: 'center',
									width: 'fit-content',
								}}
							>
								Avalehele
							</Link>
						</div>

						<p
							style={{
								textAlign: 'center',
								fontSize: '0.875rem',
								color: '#293455',
							}}
						>
							Vabandame ebamugavuste pärast. Kui probleem püsib, võtke meiega ühendust
							aadressil support@ebs.ee ja lahendame olukorra nii kiiresti kui
							võimalik.
						</p>
					</div>
				</div>
			</body>
		</html>
	);
}
